import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import * as styles from "../css/single-blog.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import StyledHero from "../components/StyledHero"
import { convertToBgImage } from "gbimage-bridge";
import {  getImage } from "gatsby-plugin-image"
import Seo from "../components/SEO";
// so first imported the disqus plugin
import { Disqus } from 'gatsby-plugin-disqus';

const Blog = ({ data }) => {
    const { slug ,id ,title , published , author, description: {childMarkdownRemark},image} = data.post.edges[0].node
    const image_info = getImage(image)
    const bgImage = convertToBgImage(image_info)
    const url = "https://webitsu.com"
    
// 
    let disqusConfig = {
      url        : `${url+'/blog/'+slug}`,
      identifier : id,
      title      : title,
    }
  

    return (
        <Layout>
          <Seo title={title}/>
            <StyledHero img={bgImage.fluid} />
            <section className={styles.blog}>
                <div className={styles.center}>
                    <h1>{title}</h1>
                    <h4>Published at : {published}</h4>
                    <h4>Author: {author} </h4>
                    <div dangerouslySetInnerHTML={{__html:childMarkdownRemark.html}}/>
                    <AniLink fade to="/blog" className="btn-primary">
                        all blogs
                    </AniLink>
                </div>
            </section>
            <div>
            <Disqus config={disqusConfig}/>   
    </div>
        </Layout>
    )
}



export const query = graphql`
query getPost($slug: String!) {
  post: allContentfulPost(filter: {slug: {eq: $slug}}) {
    edges {
      node {
        id
        title
        published
        author
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}
`

export default Blog